import * as React from "react"
import PropTypes from "prop-types"
import {useState} from "react";
import {IconPlus} from "../icons/Icons";
import {getLabel} from "../../helpers/StringHelpers";

const handleFacetClick = (key, facet, facetFilters) => {
    const filters = {...facetFilters} || {};
    const kv = key + ":" + facet.name;
    if (key in filters) {
        const index = filters[key].indexOf(kv);
        if (index !== -1) {
            filters[key].splice(index, 1);
        } else {
            filters[key].push(kv);
        }
        if(filters[key].length <= 0) {
            delete filters[key];
        }
    } else {
        filters[key] = [kv];
    }
    return filters
}

const FilterPanel = ({facets, facetFilters, handleFacet}) => {
    const initState = {}
    Object.keys(facetFilters).forEach(key => initState[key]=true);
    const [expanded, setExpanded] = useState(initState);
    const checkChecked = (key, facet) => {
        if(key in facetFilters) {
            const kv = key + ":" + facet.name;
            if(facetFilters[key].indexOf(kv) >= 0)
                return true;
        }
        return false;
    }
    const getPrice = (s) => {
        try {
            return parseInt(s.split(' ')[0].replace(',','').replace('$',''))
        } catch {
            return 0
        }
    }
    const expand = (prop) => {
        const c = {...expanded};
        if (prop in c) {
            delete c[prop];
        } else {
            c[prop] = true;
        }
        setExpanded(c);
    }
   return (
       <>
           <div className={"text-gray-500 font-italic py-2 text-center text-sm"}>Filters</div>
           {
               Object.keys(facets).sort().map((key) => {
                   const values = key === 'price_filter'
                       ? facets[key].sort((a, b) => {
                           return getPrice(a.name) - getPrice(b.name)
                       })
                       : facets[key];

                   if(key === 'categories.id') return (<></>);
                   if(key === 'categories.name') return (<></>);

                   return (
                       <div key={key} className={"w-full"}>
                           <button className={"border-t border-solid border-gray-400 py-4 font-bold flex w-full focus:outline-none"}
                                   onClick={() => {
                                       expand(key)
                                   }}>
                               <div className={"w-full text-left"}>
                                   {getLabel(key)}</div>
                               <div className={"text-right"}>
                                   {
                                       !(key in expanded)
                                           ? <IconPlus/>
                                           : <div>&ndash;</div>
                                   }
                               </div>
                           </button>
                           <div
                               className={(key in expanded)
                                   ? "border-t border-solid border-gray-400 overflow-auto max-h-60"
                                   : "hidden"}>
                               {values.map((facet, k) => {
                                   const input_id = "check_" + key + "_" + k;
                                   return (
                                       <div key={key + '_' + k} className={"py-2"}>
                                           <input type={"checkbox"} id={input_id}
                                                  checked={checkChecked(key, facet)}
                                                  onChange={(e) => {
                                                      if(handleFacet) {
                                                          handleFacet(key, facet, e)
                                                      }
                                                  }}
                                           />
                                           <label className={"pl-4"} htmlFor={input_id}>{facet.name} ({facet.count})</label>
                                       </div>
                                   )
                               })}
                           </div>
                       </div>
                   )
               })
           }
       </>
   )
}


FilterPanel.propTypes = {
    facets: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
    }))).isRequired,
    facetFilters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    handleFacet: PropTypes.func.isRequired
}

export {handleFacetClick};
export default FilterPanel;