
const PageGetSurrounding = (currentPage, perPage, totalItems, surrounding = 5) => {
    const pages = [];
    const totalPages = Math.ceil(totalItems / perPage);
    let left = currentPage - surrounding / 2 >= 1 ? Math.round(currentPage - surrounding / 2) : 1;
    let right = currentPage + surrounding / 2 <= totalPages ? currentPage + Math.round(surrounding / 2) : totalPages;
    if(left <= 1) right = left + surrounding;
    if(right >= totalPages) left = right - surrounding;
    if(left > 1) pages.push(1);
    for(let i = left <= 1 ? 1 : left; i <= right && i <= totalPages; ++i) {
        pages.push(i)
    }
    if(right < totalPages) pages.push(totalPages);
    return pages;
}

export {PageGetSurrounding}