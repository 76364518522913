import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import CategoryImage from "./CategoryImage"
import { USD } from "../../helpers/NumberHelper"
import { uniformUrl } from "../../helpers/StringHelpers"
import { ProductTypeHelper } from "../../helpers/ProductTypeHelper"

const ProductGrid = ({ products, _page, _setPage, _query, _setQuery }) => {
  return (
    <div className={"grid grid-flow-row grid-cols-2 gap-8 md:grid-cols-3 xl:grid-cols-4"}>
      {products.map(hit => {
        const url = uniformUrl("/" + hit.url_key + "/")
        return (
          <div
            key={hit.sku}
            className={
              " grid grid-rows-[auto_1fr_auto] justify-start items-start gap-2 group mb-10 text-center"
            }
          >
            <div className={" h-full duration-200 group-hover:scale-[102%]"}>
              <CategoryImage
                sku={hit.sku}
                alt={hit.name}
                title={hit.title}
                normal={hit.small_image}
                thumb={hit.thumbnail}
                url={url}
                productType={hit.productType || ProductTypeHelper(hit)}
              />
            </div>
            {(hit.brand || "").length > 0 && (hit.model || "").length > 0 ? (
              <>
                <h2 className={"text-center font-bold"}>{hit.brand}</h2>
                <p>{hit.model}</p>
              </>
            ) : (
              <>
                <h2 className={"text-center font-normal text-base"}>{hit.title}</h2>
              </>
            )}
            {/* <div className={"text-md text-center"}>{USD(hit.price)}</div> */}
            <Link
              to={url}
              className={
                "mx-auto w-auto bg-accent p-4 py-2 text-white hover:bg-link text-sm uppercase"
              }
            >
              SEE DETAILS
            </Link>
          </div>
        )
      })}
    </div>
  )
}

ProductGrid.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string.isRequired, id: PropTypes.number.isRequired })
      ).isRequired,
      url_key: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      small_image: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
      thumbnail: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
      brand: PropTypes.string,
      model: PropTypes.string,
      price: PropTypes.number,
    })
  ).isRequired,
}

export default ProductGrid
